import React, { useState, useEffect } from 'react';
import { Spinner, Button } from 'react-bootstrap';
import { URL } from '../../../../variables';
import axios from 'axios';

const ModuleList = ({
    modulos,
    expandedModulo,
    toggleExpand,
    conteudos,
    selectedContentId,
    getData,
    isConteudoConcluido,
    loadingModulo,
    permissaoProximoConteudo,
    idTreinamento,
    gerarCertificado,
    nomeTreinamento,
    token,
    conteudosModulosConcluidos
}) => {
    const [loadingCertificado, setLoadingCertificado] = useState(false);
    const [user, setUser] = useState(null);

    useEffect(() => {
        get_user();
    }, []);

    const get_user = async () => {
        fetch(`${URL}api/fiscal/get_user/`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                let user = resp.user;
                setUser(user);
            } catch (err) {
                console.log(err);
            }
        })
            .catch((err) => {
                console.log(err);
                setTimeout(() => {
                    get_user();
                }, 5000);
            });
    };

    const handleGenerateCertificate = async () => {
        if (!user) {
            console.error('Usuário não encontrado');
            return;
        }

        setLoadingCertificado(true);
        try {
            const response = await axios.post(`${URL}/api/generate-certificate`, {
                idTreinamento,
                nomeTreinamento,
                userName: user.name
            }, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'certificado.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Erro ao gerar certificado:', error);
        } finally {
            setLoadingCertificado(false);
        }
    };

    return (
        <div className="list-group">
            {modulos.map((modulo, index) => (
                <div key={index}>
                    <a
                        className={`list-group-item list-group-item-action ${expandedModulo === modulo.id ? 'active' : ''}`}
                        onClick={() => toggleExpand(modulo.id)}
                        style={{ cursor: 'pointer' }}
                    >
                        <h5>{modulo.name}</h5>
                        <p>{modulo.description}</p>
                        {loadingModulo === modulo.id && <Spinner animation="border" role="status" />}
                    </a>
                    {expandedModulo === modulo.id && (
                        <div>
                            {conteudos[modulo.id]?.map((conteudo, idx) => (
                                <a
                                    key={idx}
                                    className={`list-group-item list-group-item-action content-item d-flex justify-content-between ${selectedContentId === conteudo.id ? 'content-item-active' : ''}`}
                                    onClick={() => {
                                        if (permissaoProximoConteudo || isConteudoConcluido(conteudo.id) || conteudosModulosConcluidos[expandedModulo][selectedContentId]) {
                                            getData(conteudo.id);
                                        } else {
                                            alert("Você precisa responder todas as perguntas corretamente para prosseguir!");
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <span>{conteudo.name}</span>
                                    {isConteudoConcluido(conteudo.id) && <i className="mdi mdi-checkbox-marked-circle text-success ms-auto"></i>}
                                </a>
                            ))}
                        </div>
                    )}
                </div>
            ))}
            <div className="mt-3 text-center">
                {user && gerarCertificado[idTreinamento] && (
                    <Button variant="primary" onClick={handleGenerateCertificate} disabled={loadingCertificado}>
                        {loadingCertificado ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : 'Gerar certificado'}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ModuleList;
