import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const QuestionModal = ({
    showModal,
    handleCloseModal,
    currentQuestion,
    questoesRespondidas,
    respostaCorretaMsg,
    respostaCorreta,
    respostaUsuario,
    handleSelecionarAlternativa,
    handleSubmitModal,
    handleAlterar,
    modalVisualizacao,
    totalDeAcertos,
    formularioEnviado,
    selectedContentId,
    treinamentoId
}) => {

    return (
        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {currentQuestion && (
                        <span dangerouslySetInnerHTML={{ __html: currentQuestion.enunciado }} />
                    )}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {currentQuestion && currentQuestion.alternativas.map((alternativa, index) => (
                    <div key={index} className="d-flex align-items-center mb-2">
                        <Button
                            variant={respostaUsuario === alternativa.order ? "primary" : "outline-primary"}
                            onClick={() => handleSelecionarAlternativa(alternativa)}
                            className={`me-2 mb-4 ${modalVisualizacao && questoesRespondidas[treinamentoId]?.[selectedContentId]?.[currentQuestion.id]?.correta && (alternativa.order === respostaCorreta)}`}
                            disabled={modalVisualizacao}
                        >
                            {alternativa.order}
                        </Button>
                        <span dangerouslySetInnerHTML={{ __html: alternativa.name }} className={`ml-2 mb-4 ${modalVisualizacao && questoesRespondidas[treinamentoId]?.[selectedContentId]?.[currentQuestion.id]?.correta && (alternativa.order === respostaCorreta)}`} />
                    </div>
                ))}
            </Modal.Body>
            <Modal.Footer>
                <div className="mr-auto">
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fechar
                    </Button>
                </div>
                {!modalVisualizacao ? (
                    <Button variant="primary" onClick={handleSubmitModal}>Responder</Button>
                ) : (
                    <Button variant="primary" onClick={handleAlterar} disabled={formularioEnviado[treinamentoId]?.[selectedContentId]}>Alterar</Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default QuestionModal;
