import React from 'react';
import { URL } from '../../../../variables';

const VideoPlayer = ({ file, videoFileName, thumbnail, watchedTime, handleVideoEnded, videoRef }) => {

    return (
        <div className="video-container">
            {file ? (
                file.endsWith('.pdf') ? (
                    <iframe
                        src={`${URL}${file}`}
                        title="PDF Viewer"
                        width="100%"
                        height="500"
                        style={{ border: 'none' }}
                    />
                ) : (
                    <video
                        ref={videoRef}
                        controls
                        width="100%"
                        height="500"
                        style={{ border: 'none', objectFit: 'cover' }}
                        poster={`${URL}${thumbnail}`}
                        autoPlay
                        preload="auto"
                        onLoadedMetadata={() => {
                            videoRef.current.currentTime = watchedTime;
                        }}
                        onTimeUpdate={() => {
                            const video = videoRef.current;
                            const progress = (video.currentTime / video.duration) * 100;

                            if (progress >= 85) {
                                handleVideoEnded();
                            }

                        }
                        }                    >
                        <source src={`${URL}api/video/${videoFileName}`} type="video/mp4" />
                    </video>
                )
            ) : (
                <div className="alert alert-warning" role="alert">
                    Não foi possível encontrar o arquivo.
                </div>
            )}
        </div>
    );
};

export default VideoPlayer;
