import React from "react";
import { Modal, Button, ListGroup, Badge } from "react-bootstrap";
import "../../Css/AnswerModal.css";

const AnswerModal = ({
  showAnswerModal,
  handleCloseModal,
  totalDeAcertos,
  resetConteudoConcluido,
  handleAbrirConfirmacao,
  formularioEnviado,
  selectedContentId,
  questoesRespondidas,
  handleConcluido,
  conteudosConcluidos,
  treinamentoId,
  moduloId,
}) => {

  const renderModalContent = () => {
    const isFormularioEnviado =
      formularioEnviado[treinamentoId]?.[selectedContentId];

    if (!isFormularioEnviado) {
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Enviar Respostas?</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Deseja mesmo enviar as respostas?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Não
            </Button>
            <Button variant="primary" onClick={handleAbrirConfirmacao}>
              Sim
            </Button>
          </Modal.Footer>
        </>
      );
    } else {
      const treinamento = questoesRespondidas[treinamentoId];
      const selectedContent = treinamento?.[selectedContentId];

      const concluido =
        selectedContent &&
        Object.keys(selectedContent).length === totalDeAcertos;

      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Respostas</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>
              Seu total de acertos: <Badge bg="success">{totalDeAcertos}</Badge>
            </h4>
            <hr />
            <h4>Respostas:</h4>
            <ListGroup>
              {Object.values(
                (questoesRespondidas &&
                  questoesRespondidas[treinamentoId] &&
                  questoesRespondidas[treinamentoId][selectedContentId]) ||
                  {}
              ).map((questao, index) => (
                <ListGroup.Item
                  key={index}
                  className={`d-flex flex-column ${
                    questao.correta ? "success" : "danger"
                  }`}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <strong>Questão {index + 1}: </strong>
                    <span>{questao.correta ? "Acertou" : "Errou"}</span>
                  </div>
                  <div className="resposta">{questao.resposta}</div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Fechar
            </Button>
            <div className="ml-auto">
              <Button
                variant="primary"
                className="mr-2"
                onClick={() => resetConteudoConcluido(selectedContentId)}
              >
                Refazer
              </Button>
              {concluido && (
                <Button
                  variant="primary"
                  onClick={handleConcluido}
                  disabled={
                    conteudosConcluidos[treinamentoId]?.[moduloId]?.[
                      selectedContentId
                    ] === true
                  }
                >
                  {conteudosConcluidos[treinamentoId]?.[moduloId]?.[
                    selectedContentId
                  ] === true
                    ? "Conteúdo concluído, siga para o próximo"
                    : "Concluir"}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </>
      );
    }
  };

  return (
    <Modal show={showAnswerModal} onHide={handleCloseModal} size="lg">
      {renderModalContent()}
    </Modal>
  );
};

export default AnswerModal;
