import React from 'react';
import { Button } from 'react-bootstrap';

const QuestionsTable = ({
    questoesAll,
    isPdfFile,
    handleResponderPergunta,
    questoesRespondidas,
    videoWatched,
    permissaoProximoConteudo,
    handleVisualizarRespostas,
    respostasUsuario,
    formularioEnviado,
    selectedContentId,
    treinamentoId
}) => {
    return (
        <div className="table-rep-plugin">
            <div className="table-responsive mb-0" data-pattern="priority-columns">
                <table id="tech-companies-1" className="table table-striped">
                    <thead>
                        {questoesAll.length === 0 ? (
                            <tr>
                                <th className="col-6">Nome</th>
                            </tr>
                        ) : (
                            <tr>
                                <th className="col-6">Nome</th>
                                <th className="col-6">Ação</th>
                            </tr>
                        )}
                    </thead>
                    <tbody>
                        {questoesAll.length === 0 ? (
                            <tr>
                                <td className="col-6">
                                    <div className="alert alert-info">
                                        Não há questões para esse conteúdo.
                                    </div>
                                </td>
                            </tr>
                        ) : (
                            questoesAll.map((questao, id) => (
                                <tr key={id}>
                                    <td className="col-6">{questao.nome}</td>
                                    <td className="col-6">
                                        {isPdfFile ? (
                                            <Button
                                                variant="primary"
                                                onClick={() => handleResponderPergunta(questao)}
                                            >
                                                {questoesRespondidas[treinamentoId]?.[selectedContentId]?.[questao.id] ? "Respondida" : "Responder pergunta(s)"}
                                            </Button>
                                        ) : (
                                            videoWatched || permissaoProximoConteudo ? (
                                                <Button
                                                    variant="primary"
                                                    onClick={() => handleResponderPergunta(questao)}
                                                >
                                                    {questoesRespondidas[treinamentoId]?.[selectedContentId]?.[questao.id] ? "Respondida" : "Responder pergunta(s)"}
                                                </Button>
                                            ) : (
                                                <span className="alert alert-info">Finalize o vídeo para responder à questão.</span>
                                            )
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>

                {questoesAll.length > 0 && (
                    <Button
                        onClick={() => handleVisualizarRespostas()}
                        disabled={!questoesAll.every(questao => questoesRespondidas[treinamentoId]?.[selectedContentId]?.[questao.id])}
                    >
                        {formularioEnviado[treinamentoId]?.[selectedContentId] ? "Conferir Resposta" : (questoesAll.every(questao => questoesRespondidas[treinamentoId]?.[selectedContentId]?.[questao.id]) ? "Enviar Respostas" : "Preencha Todas as Respostas")}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default QuestionsTable;
