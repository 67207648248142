import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = () => {

    return (
        <div className='row'>
            {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="col-lg-3 col-md-4 col-sm-6 mb-4">
                    <div className="card h-100">
                        <div className="card-body d-flex flex-column">
                            <Skeleton height={30} width={`80%`} />
                            <Skeleton count={3} />
                            <Skeleton height={36} width={`60%`} className="mt-auto" />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default LoadingSkeleton;
