
import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter,Redirect } from 'react-router-dom';
import {logout} from '../actions/AppActions';
import { connect } from 'react-redux';

// users
import user4 from '../assets/images/users/user-4.jpg';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            redirect:false,
            path:'/'
        };
        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    logout(){
        localStorage.removeItem('token');
        // this.setState({redirect:true,path:'/'});
        this.props.logout();
    }


    render() {

        return (
            <React.Fragment>
                {this.state.redirect == true && <Redirect to={this.state.path} />}
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block" >
                    <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                        <img className="rounded-circle header-profile-user" src={user4} height="50" alt="Header Avatar" />
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem tag="a" onClick={this.logout.bind(this)}><i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>Logout</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}
const mapsStateToProps = (state) => (
    {
    }
);

export default withRouter(connect(mapsStateToProps, { logout })(ProfileMenu));


