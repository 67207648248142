import React from 'react';
import "../../Css/NextArrow.css";

const NextArrow = ({ className, style, onClick }) => {
    const handleClick = () => {
        if (onClick && typeof onClick === 'function') {
            onClick();
        }
    };

    return (
        <div
            className={`${className} custom-next-arrow`}
            style={{ ...style, display: 'block' }}
            onClick={handleClick} 
        >
            <span className="arrow-right"></span>
        </div>
    );
};

export default NextArrow;
