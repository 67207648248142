import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = () => {
    return (
        <>
            <Skeleton height={50} width={`80%`} />
            <div className="row mt-4">
                <div className="col-md-8">
                    <Skeleton height={500} />
                    <Skeleton count={5} />
                </div>
                <div className="col-md-4">
                    <Skeleton height={50} count={3} />
                </div>
            </div>
        </>
    );
};

export default LoadingSkeleton;
